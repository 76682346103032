import { FunctionComponent, useMemo, type CSSProperties } from "react";
import styles from "./SizeMediumStateDefaultTy.module.css";

type SizeMediumStateDefaultTyType = {
  buttonSize?: string;

  /** Style props */
  sizeMediumStateDefaultTyCursor?: CSSProperties["cursor"];
  sizeMediumStateDefaultTyBorder?: CSSProperties["border"];
  sizeMediumStateDefaultTyPadding?: CSSProperties["padding"];
  sizeMediumStateDefaultTyBackgroundColor?: CSSProperties["backgroundColor"];
  buttonMediumCursor?: CSSProperties["cursor"];
  buttonMediumBackgroundColor?: CSSProperties["backgroundColor"];
  textBoxSizing?: CSSProperties["boxSizing"];
  buttonTextDisplay?: CSSProperties["display"];
  buttonTextColor?: CSSProperties["color"];

  /** Action props */
  onClose?: () => void;
};

const SizeMediumStateDefaultTy: FunctionComponent<
  SizeMediumStateDefaultTyType
> = ({
  buttonSize,
  sizeMediumStateDefaultTyCursor,
  sizeMediumStateDefaultTyBorder,
  sizeMediumStateDefaultTyPadding,
  sizeMediumStateDefaultTyBackgroundColor,
  buttonMediumCursor,
  buttonMediumBackgroundColor,
  textBoxSizing,
  buttonTextDisplay,
  buttonTextColor,
  onClose,
}) => {
  const sizeMediumStateDefaultTyStyle: CSSProperties = useMemo(() => {
    return {
      cursor: sizeMediumStateDefaultTyCursor,
      border: sizeMediumStateDefaultTyBorder,
      padding: sizeMediumStateDefaultTyPadding,
      backgroundColor: sizeMediumStateDefaultTyBackgroundColor,
    };
  }, [
    sizeMediumStateDefaultTyCursor,
    sizeMediumStateDefaultTyBorder,
    sizeMediumStateDefaultTyPadding,
    sizeMediumStateDefaultTyBackgroundColor,
  ]);

  const buttonMediumStyle: CSSProperties = useMemo(() => {
    return {
      cursor: buttonMediumCursor,
      backgroundColor: buttonMediumBackgroundColor,
    };
  }, [buttonMediumCursor, buttonMediumBackgroundColor]);

  const textStyle: CSSProperties = useMemo(() => {
    return {
      boxSizing: textBoxSizing,
    };
  }, [textBoxSizing]);

  const buttonTextStyle: CSSProperties = useMemo(() => {
    return {
      display: buttonTextDisplay,
      color: buttonTextColor,
    };
  }, [buttonTextDisplay, buttonTextColor]);

  return (
    <div
      className={styles.sizemediumStatedefaultTy}
      style={sizeMediumStateDefaultTyStyle}
      onClick={onClose}
    >
      <div className={styles.buttonMedium} style={buttonMediumStyle}>
        <div className={styles.content}>
          <div className={styles.iconLeft}>
            <div className={styles.iconPlaceholder}>
              <div className={styles.base} />
              <div className={styles.base1} />
              <div className={styles.base2} />
              <div className={styles.base3} />
              <div className={styles.base4} />
            </div>
          </div>
          <div className={styles.text} style={textStyle}>
            <b className={styles.buttonText} style={buttonTextStyle}>
              {buttonSize}
            </b>
          </div>
          <div className={styles.iconLeft}>
            <div className={styles.iconPlaceholder}>
              <div className={styles.base} />
              <div className={styles.base1} />
              <div className={styles.base2} />
              <div className={styles.base3} />
              <div className={styles.base4} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SizeMediumStateDefaultTy;
