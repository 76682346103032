import { FunctionComponent, useState, useCallback } from "react";
import VideoPopUp from "./VideoPopUp";
import PortalPopup from "./PortalPopup";
import TermsAndConditions from "./TermsAndConditions";
import IconPlaceholder from "./IconPlaceholder";
import styles from "./ContentLeft.module.css";

const ContentLeft: FunctionComponent = () => {
  const [isVideoPopUpOpen, setVideoPopUpOpen] = useState(false);
  const [isTermsAndConditionsPopupOpen, setTermsAndConditionsPopupOpen] =
    useState(false);

  const openVideoPopUp = useCallback(() => {
    setVideoPopUpOpen(true);
  }, []);

  const closeVideoPopUp = useCallback(() => {
    setVideoPopUpOpen(false);
  }, []);

  const openTermsAndConditionsPopup = useCallback(() => {
    setTermsAndConditionsPopupOpen(true);
  }, []);

  const closeTermsAndConditionsPopup = useCallback(() => {
    setTermsAndConditionsPopupOpen(false);
  }, []);

  return (
    <>
      <aside className={styles.contentleft}>
        <div className={styles.welcomeToThe}>Welcome to the SamePlace</div>
        <label className={styles.improveLivesOf}>
          Improve lives of a billion people, and operations of a million
          businesses by year 2030.
        </label>
        <div className={styles.buttonlearnmoreParent}>
          <button className={styles.buttonlearnmore} onClick={openVideoPopUp}>
            <div className={styles.content}>
              <div className={styles.vectorParent}>
                <img className={styles.vectorIcon} alt="" src="/vector1.svg" />
                <b className={styles.buttonText}>Learn More</b>
              </div>
              <div className={styles.iconRight}>
                <IconPlaceholder
                  iconPlaceholderWidth="20px"
                  iconPlaceholderHeight="20px"
                  iconPlaceholderPosition="absolute"
                  iconPlaceholderTop="calc(50% - 10px)"
                  iconPlaceholderLeft="calc(50% - 10px)"
                  iconPlaceholderFlexShrink="unset"
                  baseHeight="66.5%"
                  baseWidth="66.5%"
                  baseTop="16.5%"
                  baseRight="17%"
                  baseBottom="17%"
                  baseLeft="16.5%"
                  baseTop1="21%"
                  baseRight1="54%"
                  baseBottom1="54%"
                  baseLeft1="21%"
                  baseHeight1="25%"
                  baseWidth1="25%"
                  baseBorderRadius="0px 2px 2px 2px"
                  baseBackgroundColor="#d2d1d4"
                  baseTop2="54%"
                  baseRight2="54%"
                  baseBottom2="21%"
                  baseLeft2="21%"
                  baseHeight2="25%"
                  baseWidth2="25%"
                  baseBorderRadius1="2px 2px 2px 0px"
                  baseBackgroundColor1="#d2d1d4"
                  baseTop3="21%"
                  baseRight3="21%"
                  baseBottom3="54%"
                  baseLeft3="54%"
                  baseHeight3="25%"
                  baseWidth3="25%"
                  baseBorderRadius2="2px 0px 2px 2px"
                  baseBackgroundColor2="#d2d1d4"
                  baseTop4="54%"
                  baseRight4="21%"
                  baseBottom4="21%"
                  baseLeft4="54%"
                  baseHeight4="25%"
                  baseWidth4="25%"
                  baseBorderRadius3="2px 2px 0px 2px"
                  baseBackgroundColor3="#d2d1d4"
                />
              </div>
            </div>
          </button>
          <button
            className={styles.buttontac}
            onClick={openTermsAndConditionsPopup}
          >
            <div className={styles.content1}>
              <div className={styles.buttonTextWrapper}>
                <b className={styles.buttonText1}>Terms and Conditions</b>
              </div>
              <div className={styles.iconRight}>
                <IconPlaceholder
                  iconPlaceholderWidth="20px"
                  iconPlaceholderHeight="20px"
                  iconPlaceholderPosition="absolute"
                  iconPlaceholderTop="calc(50% - 10px)"
                  iconPlaceholderLeft="calc(50% - 10px)"
                  iconPlaceholderFlexShrink="unset"
                  baseHeight="66.5%"
                  baseWidth="66.5%"
                  baseTop="16.5%"
                  baseRight="17%"
                  baseBottom="17%"
                  baseLeft="16.5%"
                  baseTop1="21%"
                  baseRight1="54%"
                  baseBottom1="54%"
                  baseLeft1="21%"
                  baseHeight1="25%"
                  baseWidth1="25%"
                  baseBorderRadius="0px 2px 2px 2px"
                  baseBackgroundColor="#d2d1d4"
                  baseTop2="54%"
                  baseRight2="54%"
                  baseBottom2="21%"
                  baseLeft2="21%"
                  baseHeight2="25%"
                  baseWidth2="25%"
                  baseBorderRadius1="2px 2px 2px 0px"
                  baseBackgroundColor1="#d2d1d4"
                  baseTop3="21%"
                  baseRight3="21%"
                  baseBottom3="54%"
                  baseLeft3="54%"
                  baseHeight3="25%"
                  baseWidth3="25%"
                  baseBorderRadius2="2px 0px 2px 2px"
                  baseBackgroundColor2="#d2d1d4"
                  baseTop4="54%"
                  baseRight4="21%"
                  baseBottom4="21%"
                  baseLeft4="54%"
                  baseHeight4="25%"
                  baseWidth4="25%"
                  baseBorderRadius3="2px 2px 0px 2px"
                  baseBackgroundColor3="#d2d1d4"
                />
              </div>
            </div>
          </button>
        </div>
      </aside>
      {isVideoPopUpOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeVideoPopUp}
        >
          <VideoPopUp onClose={closeVideoPopUp} />
        </PortalPopup>
      )}
      {isTermsAndConditionsPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.5)"
          placement="Centered"
        >
          <TermsAndConditions onClose={closeTermsAndConditionsPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default ContentLeft;
