import { FunctionComponent } from "react";
import styles from "./VideoPopUp.module.css";

type VideoPopUpType = {
  onClose?: () => void;
};

const VideoPopUp: FunctionComponent<VideoPopUpType> = ({ onClose }) => {
  return (
    <div className={styles.videopopup}>
      <video className={styles.video} controls onClick={onClose}>
        <source src="/PitchDemo.mp4" />
      </video>
    </div>
  );
};

export default VideoPopUp;
