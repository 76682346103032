import { FunctionComponent, useMemo, type CSSProperties } from "react";
import styles from "./IconPlaceholder.module.css";

type IconPlaceholderType = {
  /** Style props */
  iconPlaceholderWidth?: CSSProperties["width"];
  iconPlaceholderHeight?: CSSProperties["height"];
  iconPlaceholderPosition?: CSSProperties["position"];
  iconPlaceholderTop?: CSSProperties["top"];
  iconPlaceholderLeft?: CSSProperties["left"];
  iconPlaceholderFlexShrink?: CSSProperties["flexShrink"];
  baseHeight?: CSSProperties["height"];
  baseWidth?: CSSProperties["width"];
  baseTop?: CSSProperties["top"];
  baseRight?: CSSProperties["right"];
  baseBottom?: CSSProperties["bottom"];
  baseLeft?: CSSProperties["left"];
  baseTop1?: CSSProperties["top"];
  baseRight1?: CSSProperties["right"];
  baseBottom1?: CSSProperties["bottom"];
  baseLeft1?: CSSProperties["left"];
  baseHeight1?: CSSProperties["height"];
  baseWidth1?: CSSProperties["width"];
  baseBorderRadius?: CSSProperties["borderRadius"];
  baseBackgroundColor?: CSSProperties["backgroundColor"];
  baseTop2?: CSSProperties["top"];
  baseRight2?: CSSProperties["right"];
  baseBottom2?: CSSProperties["bottom"];
  baseLeft2?: CSSProperties["left"];
  baseHeight2?: CSSProperties["height"];
  baseWidth2?: CSSProperties["width"];
  baseBorderRadius1?: CSSProperties["borderRadius"];
  baseBackgroundColor1?: CSSProperties["backgroundColor"];
  baseTop3?: CSSProperties["top"];
  baseRight3?: CSSProperties["right"];
  baseBottom3?: CSSProperties["bottom"];
  baseLeft3?: CSSProperties["left"];
  baseHeight3?: CSSProperties["height"];
  baseWidth3?: CSSProperties["width"];
  baseBorderRadius2?: CSSProperties["borderRadius"];
  baseBackgroundColor2?: CSSProperties["backgroundColor"];
  baseTop4?: CSSProperties["top"];
  baseRight4?: CSSProperties["right"];
  baseBottom4?: CSSProperties["bottom"];
  baseLeft4?: CSSProperties["left"];
  baseHeight4?: CSSProperties["height"];
  baseWidth4?: CSSProperties["width"];
  baseBorderRadius3?: CSSProperties["borderRadius"];
  baseBackgroundColor3?: CSSProperties["backgroundColor"];
};

const IconPlaceholder: FunctionComponent<IconPlaceholderType> = ({
  iconPlaceholderWidth,
  iconPlaceholderHeight,
  iconPlaceholderPosition,
  iconPlaceholderTop,
  iconPlaceholderLeft,
  iconPlaceholderFlexShrink,
  baseHeight,
  baseWidth,
  baseTop,
  baseRight,
  baseBottom,
  baseLeft,
  baseTop1,
  baseRight1,
  baseBottom1,
  baseLeft1,
  baseHeight1,
  baseWidth1,
  baseBorderRadius,
  baseBackgroundColor,
  baseTop2,
  baseRight2,
  baseBottom2,
  baseLeft2,
  baseHeight2,
  baseWidth2,
  baseBorderRadius1,
  baseBackgroundColor1,
  baseTop3,
  baseRight3,
  baseBottom3,
  baseLeft3,
  baseHeight3,
  baseWidth3,
  baseBorderRadius2,
  baseBackgroundColor2,
  baseTop4,
  baseRight4,
  baseBottom4,
  baseLeft4,
  baseHeight4,
  baseWidth4,
  baseBorderRadius3,
  baseBackgroundColor3,
}) => {
  const iconPlaceholderStyle: CSSProperties = useMemo(() => {
    return {
      width: iconPlaceholderWidth,
      height: iconPlaceholderHeight,
      position: iconPlaceholderPosition,
      top: iconPlaceholderTop,
      left: iconPlaceholderLeft,
      flexShrink: iconPlaceholderFlexShrink,
    };
  }, [
    iconPlaceholderWidth,
    iconPlaceholderHeight,
    iconPlaceholderPosition,
    iconPlaceholderTop,
    iconPlaceholderLeft,
    iconPlaceholderFlexShrink,
  ]);

  const baseStyle: CSSProperties = useMemo(() => {
    return {
      height: baseHeight,
      width: baseWidth,
      top: baseTop,
      right: baseRight,
      bottom: baseBottom,
      left: baseLeft,
    };
  }, [baseHeight, baseWidth, baseTop, baseRight, baseBottom, baseLeft]);

  const base1Style: CSSProperties = useMemo(() => {
    return {
      top: baseTop1,
      right: baseRight1,
      bottom: baseBottom1,
      left: baseLeft1,
      height: baseHeight1,
      width: baseWidth1,
      borderRadius: baseBorderRadius,
      backgroundColor: baseBackgroundColor,
    };
  }, [
    baseTop1,
    baseRight1,
    baseBottom1,
    baseLeft1,
    baseHeight1,
    baseWidth1,
    baseBorderRadius,
    baseBackgroundColor,
  ]);

  const base2Style: CSSProperties = useMemo(() => {
    return {
      top: baseTop2,
      right: baseRight2,
      bottom: baseBottom2,
      left: baseLeft2,
      height: baseHeight2,
      width: baseWidth2,
      borderRadius: baseBorderRadius1,
      backgroundColor: baseBackgroundColor1,
    };
  }, [
    baseTop2,
    baseRight2,
    baseBottom2,
    baseLeft2,
    baseHeight2,
    baseWidth2,
    baseBorderRadius1,
    baseBackgroundColor1,
  ]);

  const base3Style: CSSProperties = useMemo(() => {
    return {
      top: baseTop3,
      right: baseRight3,
      bottom: baseBottom3,
      left: baseLeft3,
      height: baseHeight3,
      width: baseWidth3,
      borderRadius: baseBorderRadius2,
      backgroundColor: baseBackgroundColor2,
    };
  }, [
    baseTop3,
    baseRight3,
    baseBottom3,
    baseLeft3,
    baseHeight3,
    baseWidth3,
    baseBorderRadius2,
    baseBackgroundColor2,
  ]);

  const base4Style: CSSProperties = useMemo(() => {
    return {
      top: baseTop4,
      right: baseRight4,
      bottom: baseBottom4,
      left: baseLeft4,
      height: baseHeight4,
      width: baseWidth4,
      borderRadius: baseBorderRadius3,
      backgroundColor: baseBackgroundColor3,
    };
  }, [
    baseTop4,
    baseRight4,
    baseBottom4,
    baseLeft4,
    baseHeight4,
    baseWidth4,
    baseBorderRadius3,
    baseBackgroundColor3,
  ]);

  return (
    <div className={styles.iconPlaceholder} style={iconPlaceholderStyle}>
      <div className={styles.base} style={baseStyle} />
      <div className={styles.base1} style={base1Style} />
      <div className={styles.base2} style={base2Style} />
      <div className={styles.base3} style={base3Style} />
      <div className={styles.base4} style={base4Style} />
    </div>
  );
};

export default IconPlaceholder;
