import { FunctionComponent, useState, useCallback } from "react";
// import ContactForm from "./ContactForm";
import PortalPopup from "./PortalPopup";
import IconPlaceholder from "./IconPlaceholder";
import styles from "./Header.module.css";

const Header: FunctionComponent = () => {
  // const [isContactFormPopupOpen, setContactFormPopupOpen] = useState(false);

  // const openContactFormPopup = useCallback(() => {
  //   setContactFormPopupOpen(true);
  // }, []);

  // const closeContactFormPopup = useCallback(() => {
  //   setContactFormPopupOpen(false);
  // }, []);

  return (
    <>
      <div className={styles.navigation}>
        <img className={styles.logoIcon} alt="" src="/logo.svg" />
        {/* <button
          className={styles.buttonstartjourney}
          onClick={openContactFormPopup}
        >
          <div className={styles.content}>
            <div className={styles.buttonTextWrapper}>
              <b className={styles.buttonText}>Start Our Journey</b>
            </div>
            <div className={styles.iconRight}>
              <IconPlaceholder
                iconPlaceholderWidth="20px"
                iconPlaceholderHeight="20px"
                iconPlaceholderPosition="absolute"
                iconPlaceholderTop="calc(50% - 10px)"
                iconPlaceholderLeft="calc(50% - 10px)"
                iconPlaceholderFlexShrink="unset"
                baseHeight="66.5%"
                baseWidth="66.5%"
                baseTop="16.5%"
                baseRight="17%"
                baseBottom="17%"
                baseLeft="16.5%"
                baseTop1="21%"
                baseRight1="54%"
                baseBottom1="54%"
                baseLeft1="21%"
                baseHeight1="25%"
                baseWidth1="25%"
                baseBorderRadius="0px 2px 2px 2px"
                baseBackgroundColor="#d2d1d4"
                baseTop2="54%"
                baseRight2="54%"
                baseBottom2="21%"
                baseLeft2="21%"
                baseHeight2="25%"
                baseWidth2="25%"
                baseBorderRadius1="2px 2px 2px 0px"
                baseBackgroundColor1="#d2d1d4"
                baseTop3="21%"
                baseRight3="21%"
                baseBottom3="54%"
                baseLeft3="54%"
                baseHeight3="25%"
                baseWidth3="25%"
                baseBorderRadius2="2px 0px 2px 2px"
                baseBackgroundColor2="#d2d1d4"
                baseTop4="54%"
                baseRight4="21%"
                baseBottom4="21%"
                baseLeft4="54%"
                baseHeight4="25%"
                baseWidth4="25%"
                baseBorderRadius3="2px 2px 0px 2px"
                baseBackgroundColor3="#d2d1d4"
              />
            </div>
          </div>
        </button> */}
      </div>
      {/* {isContactFormPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.5)"
          placement="Centered"
        >
          <ContactForm onClose={closeContactFormPopup} />
        </PortalPopup>
      )} */}
    </>
  );
};

export default Header;
