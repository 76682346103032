import { FunctionComponent } from "react";
import SizeMediumStateDefaultTy from "./SizeMediumStateDefaultTy";
import styles from "./TermsAndConditions.module.css";

type TermsAndConditionsType = {
  onClose?: () => void;
};

const TermsAndConditions: FunctionComponent<TermsAndConditionsType> = ({
  onClose,
}) => {
  return (
    <div className={styles.termsandconditions}>
      <div className={styles.termsandconditionspopup}>
        <div className={styles.topIcons}>
          <button className={styles.basicsX} onClick={onClose}>
            <img className={styles.vectorIcon} alt="" src="/vector.svg" />
          </button>
          <div className={styles.icon}>
            <img
              className={styles.essentialsShieldTick}
              alt=""
              src="/essentials--shieldtick.svg"
            />
          </div>
        </div>
        <p className={styles.termsAndConditions}>Terms and Conditions</p>
        <p className={styles.introduction11TheseContainer}>
          <span className={styles.introduction}>
            <span className={styles.introduction1}>
              <span>
                <b>1. Introduction</b>
              </span>
            </span>
          </span>
          <ul className={styles.theseTermsOfServiceTosO}>
            <li className={styles.bySubscribingToOurMailing}>
              <span className={styles.introduction1}>
                <span>
                  <span>
                    1.1. These Terms of Service (ToS) outline the rules and
                    regulations for subscribing to and using our mailing list
                    service.
                  </span>
                </span>
              </span>
            </li>
            <li className={styles.bySubscribingToOurMailing}>
              <span className={styles.introduction1}>
                <span>
                  <span>
                    1.2. By subscribing to our mailing list, you agree to comply
                    with these terms.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <span className={styles.introduction}>
            <span className={styles.introduction1}>
              <span>
                <b>2. Data Collection and Consent</b>
              </span>
            </span>
          </span>
          <ul className={styles.theseTermsOfServiceTosO}>
            <li className={styles.bySubscribingToOurMailing}>
              <span className={styles.introduction1}>
                <span>
                  <span>
                    2.1. We collect and process personal data for the purpose of
                    sending you marketing or informational emails.
                  </span>
                </span>
              </span>
            </li>
            <li className={styles.bySubscribingToOurMailing}>
              <span className={styles.introduction1}>
                <span>
                  <span>
                    2.2. We only collect data with your explicit consent, and
                    you may withdraw your consent at any time.
                  </span>
                </span>
              </span>
            </li>
            <li className={styles.bySubscribingToOurMailing}>
              <span className={styles.introduction1}>
                <span>
                  <span>
                    2.3. We ensure that all data collected is treated in
                    compliance with the General Data Protection Regulation
                    (GDPR) and other relevant data protection laws.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <span className={styles.introduction}>
            <span className={styles.introduction1}>
              <span>
                <b>3. Data Usage</b>
              </span>
            </span>
          </span>
          <ul className={styles.theseTermsOfServiceTosO}>
            <li className={styles.bySubscribingToOurMailing}>
              <span className={styles.introduction1}>
                <span>
                  <span>
                    3.1. We will use your data solely for the purpose for which
                    it was collected – to send you emails related to our
                    services or promotions.
                  </span>
                </span>
              </span>
            </li>
            <li className={styles.bySubscribingToOurMailing}>
              <span className={styles.introduction1}>
                <span>
                  <span>
                    3.2. Your data will not be shared with third parties without
                    your explicit consent.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <span className={styles.introduction}>
            <span className={styles.introduction1}>
              <span>
                <b>4. Data Security</b>
              </span>
            </span>
          </span>
          <ul className={styles.theseTermsOfServiceTosO}>
            <li className={styles.bySubscribingToOurMailing}>
              <span className={styles.introduction1}>
                <span>
                  <span>
                    4.1. We implement appropriate security measures to protect
                    your data from unauthorized access, disclosure, alteration,
                    or destruction.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <span className={styles.introduction}>
            <span className={styles.introduction1}>
              <span>
                <b>5. Data Retention</b>
              </span>
            </span>
          </span>
          <ul className={styles.theseTermsOfServiceTosO}>
            <li className={styles.bySubscribingToOurMailing}>
              <span className={styles.introduction1}>
                <span>
                  <span>
                    5.1. We will retain your data only as long as necessary for
                    the purpose for which it was collected. You may request its
                    removal at any time.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <span className={styles.introduction}>
            <span className={styles.introduction1}>
              <span>
                <b>6. Your Rights</b>
              </span>
            </span>
          </span>
          <ul className={styles.theseTermsOfServiceTosO}>
            <li className={styles.bySubscribingToOurMailing}>
              <span className={styles.introduction1}>
                <span>
                  <span>{`6.1. You have the right to access, correct, or delete your personal data. To exercise these rights, contact us at `}</span>
                </span>
                <b className={styles.tossameplaceio}>tos@sameplace.io</b>
                <span>
                  <span className={styles.span}>.</span>
                </span>
              </span>
            </li>
          </ul>
          <span className={styles.introduction}>
            <span className={styles.introduction1}>
              <span>
                <b>7. Cookie Usage</b>
              </span>
            </span>
          </span>
          <ul className={styles.theseTermsOfServiceTosO}>
            <li className={styles.bySubscribingToOurMailing}>
              <span className={styles.introduction1}>
                <span>
                  <span>
                    7.1. We may use cookies on our website to enhance your user
                    experience. By using our website and agreeing to these ToS,
                    you consent to our use of cookies.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <span className={styles.introduction}>
            <span className={styles.introduction1}>
              <span>
                <b>8. Changes to Terms</b>
              </span>
            </span>
          </span>
          <ul className={styles.theseTermsOfServiceTosO}>
            <li className={styles.bySubscribingToOurMailing}>
              <span className={styles.introduction1}>
                <span>
                  <span>
                    8.1. We reserve the right to amend these ToS at any time.
                    Any changes will be communicated to subscribers.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <span className={styles.introduction}>
            <span className={styles.introduction1}>
              <span>
                <b>9. Contact Information</b>
              </span>
            </span>
          </span>
          <ul className={styles.theseTermsOfServiceTosO}>
            <li className={styles.bySubscribingToOurMailing}>
              <span className={styles.introduction1}>
                <span>
                  <span>{`9.1. For any inquiries or requests regarding your data or these ToS, please contact us at `}</span>
                  <b className={styles.span}>tos@sameplace.io</b>
                  <span className={styles.span}>.</span>
                </span>
              </span>
            </li>
          </ul>
          <span className={styles.introduction}>
            <span className={styles.introduction1}>
              <span>
                <b>10. Acceptance</b>
              </span>
            </span>
          </span>
          <ul className={styles.theseTermsOfServiceTosO}>
            <li className={styles.bySubscribingToOurMailing}>
              <span className={styles.introduction1}>
                <span>
                  <span>
                    10.1. By subscribing to our mailing list, you acknowledge
                    that you have read, understood, and agreed to these Terms of
                    Service.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <span className={styles.introduction}>
            <span className={styles.introduction1}>
              <span>
                <b>11. Deletion of Personal Data</b>
              </span>
            </span>
          </span>
          <ul className={styles.theseTermsOfServiceTosO}>
            <li className={styles.bySubscribingToOurMailing}>
              <span>
                <span className={styles.youHaveThe}>
                  11.1. Right to Deletion
                </span>
              </span>
            </li>
            <li className={styles.bySubscribingToOurMailing}>
              <span>
                <span className={styles.youHaveThe}>
                  11.1.1. You have the right to request the deletion of your
                  personal data from our mailing list at any time, and we are
                  committed to fulfilling this request within a reasonable
                  timeframe.
                </span>
              </span>
            </li>
            <li className={styles.bySubscribingToOurMailing}>
              <span>
                <span className={styles.youHaveThe}>
                  11.2. How to Request Deletion
                </span>
              </span>
            </li>
            <li className={styles.bySubscribingToOurMailing}>
              <span>
                <span
                  className={styles.youHaveThe}
                >{`11.2.1. To request the deletion of your personal data, please contact us at `}</span>
              </span>
              <b className={styles.tossameplaceio2}>tos@sameplace.io</b>
              <span className={styles.orUseThe}>
                {" "}
                or use the designated opt-out mechanism provided in our emails.
              </span>
            </li>
            <li className={styles.bySubscribingToOurMailing}>
              <span className={styles.orUseThe}>
                11.3. Data Removal Process
              </span>
            </li>
            <li className={styles.bySubscribingToOurMailing}>
              <span className={styles.orUseThe}>
                11.3.1. Upon receiving a deletion request, we will promptly
                remove your personal data from our mailing list, and you will no
                longer receive communications from us.
              </span>
            </li>
            <li className={styles.bySubscribingToOurMailing}>
              <span className={styles.orUseThe}>
                11.3.2. We will also ensure that any third parties to whom your
                data has been disclosed are informed of your request and comply
                with it.
              </span>
            </li>
            <li className={styles.bySubscribingToOurMailing}>
              <span className={styles.orUseThe}>
                11.4. Data Retention after Deletion
              </span>
            </li>
            <li className={styles.bySubscribingToOurMailing}>
              <span className={styles.orUseThe}>
                11.4.1. After your data is deleted, we may retain limited
                information to maintain a record of your request and to ensure
                compliance with data protection regulations.
              </span>
            </li>
            <li className={styles.bySubscribingToOurMailing}>
              <span className={styles.orUseThe}>
                11.5. Confirmation of Deletion
              </span>
            </li>
            <li>
              <span className={styles.orUseThe}>
                11.5.1. We will provide you with confirmation once your data has
                been deleted, including the steps taken to ensure it is no
                longer used for mailing list purposes.
              </span>
            </li>
          </ul>
        </p>
        <SizeMediumStateDefaultTy
          buttonSize="Close"
          sizeMediumStateDefaultTyCursor="pointer"
          sizeMediumStateDefaultTyBorder="none"
          sizeMediumStateDefaultTyPadding="0"
          sizeMediumStateDefaultTyBackgroundColor="transparent"
          buttonMediumCursor="pointer"
          buttonMediumBackgroundColor="transparent"
          textBoxSizing="border-box"
          buttonTextDisplay="inline-block"
          buttonTextColor="#1d2939"
          onClose={onClose}
        />
        <div className={styles.sameplaceioAllRightsContainer}>
          <span>{`© 2023 Sameplace.io, All rights reserved. Get in touch with us `}</span>
          <span className={styles.here}>here.</span>
          <span className={styles.span2}>{` `}</span>
          <span>Terms and Conditions.</span>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
