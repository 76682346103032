import { FunctionComponent } from "react";
import Header from "../components/Header";
import ContentLeft from "../components/ContentLeft";
import styles from "./Homepage.module.css";

const Homepage: FunctionComponent = () => {
  return (
    <div className={styles.homepage}>
      <Header />
      <section className={styles.content}>
        <ContentLeft />
        <img
          className={styles.ilustrationIcon}
          alt=""
          src="/ilustration@2x.png"
        />
      </section>
      <div className={styles.bottom}>
        <div className={styles.sameplaceioAllRightsContainer}>
          <span>{`© 2024 Sameplace.io, All rights reserved. Get in touch with us `}</span>
          <span className={styles.here}><a href="mailto:office@sameplace.io">here.</a> </span>
          {/* <span className={styles.here}>here.</span> */}
          <span className={styles.span}>{` `}</span>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
